/* eslint-disable */
<template>
  <div class="login-register-container">
    <div class="content-wrapper">
      <h1 class="game-title">Get7</h1>
      <div class="form-container" :class="{ 'show-register': showRegister }">
        <div class="form-box login" v-show="!showRegister">
          <h2>Welcome to Get7</h2>
          <p>The push your luck trivia game</p>
          <form @submit.prevent="login">
            <div class="input-group">
              <input v-model="email" type="email" required>
              <label>Email</label>
            </div>
            <div class="input-group">
              <input v-model="password" type="password" required>
              <label>Password</label>
            </div>
            <button type="submit" class="btn">Login</button>
          </form>
          <button @click="loginWithGoogle" class="btn google-btn">
            <i class="fab fa-google"></i> Login with Google
          </button>
          <p>Don't have an account? <a href="#" @click.prevent="showRegister = true">Register</a></p>
        </div>

        <div class="form-box register" v-show="showRegister">
          <h2>Create Account</h2>
          <form @submit.prevent="register">
            <div class="input-group">
              <input v-model="email" type="email" required>
              <label>Email</label>
            </div>
            <div class="input-group">
              <input v-model="password" type="password" required>
              <label>Password</label>
            </div>
            <div class="input-group">
              <input v-model="username" type="text" required>
              <label>Username for Leaderboard</label>
            </div>
            <button type="submit" class="btn">Register</button>
          </form>
          <button @click="loginWithGoogle" class="btn google-btn">
            <i class="fab fa-google"></i> Register with Google
          </button>
          <p>Already have an account? <a href="#" @click.prevent="showRegister = false">Login</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, increment, arrayUnion } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const email = ref('');
    const password = ref('');
    const username = ref('');
    const showRegister = ref(false);

    const redirectTo = route.query.redirect || '/play'; // Default to '/play' if no redirect is specified

    const login = async () => {
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        console.log("User signed in successfully");
        await handleAnonymousScore();
        router.push(redirectTo);
      } catch (error) {
        console.error("Login error:", error);
        alert(error.message);
      }
    };

    const register = async () => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
        const user = userCredential.user;
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          roles: ['player'],
          username: username.value,
          createdAt: new Date().toISOString()
        });

        await handleAnonymousScore();
        router.push(redirectTo);
      } catch (error) {
        console.error("Registration error:", error);
        alert(error.message);
      }
    };
    
    const loginWithGoogle = async () => {
      try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        console.log("User signed in with Google successfully");

        // Check if user profile exists
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          console.log("User document does not exist, redirecting to complete profile");
          // Store the anonymous score temporarily
          const anonymousScore = localStorage.getItem('anonymousScore');
          if (anonymousScore) {
            localStorage.setItem('tempAnonymousScore', anonymousScore);
          }
          // Redirect to the profile completion page
          router.push('/complete-profile');
        } else {
          // If user document exists, proceed with the normal flow
          await handleAnonymousScore();
          router.push(redirectTo);
        }
      } catch (error) {
        console.error("Error signing in with Google:", error);
        alert(error.message);
      }
    };
    
    const forgotPassword = async () => {
      if (!email.value) {
        alert('Please enter your email address in the email field.');
        return;
      }
      try {
        await sendPasswordResetEmail(auth, email.value);
        alert('Password reset email sent. Please check your inbox.');
      } catch (error) {
        console.error('Error sending password reset email:', error);
        alert('Error sending password reset email: ' + error.message);
      }
    };

    // Add this new method
    const checkUserAuth = () => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("User is signed in:");
        } else {
          console.log("No user is signed in");
        }
      });
    };

    // Call this method in onMounted
    onMounted(() => {
      checkUserAuth();
    });

    const handleAnonymousScore = async () => {
      const anonymousScore = localStorage.getItem('anonymousScore');
      if (anonymousScore) {
        const parsedScore = JSON.parse(anonymousScore);
        await saveScore(parsedScore);
        await updateWeeklyLeaderboard(parsedScore);
        localStorage.removeItem('anonymousScore');
      }
    };

    const saveScore = async (scoreData) => {
      const user = auth.currentUser;
      if (user && scoreData) {
        const scoreRef = doc(db, `users/${user.uid}/scores/${scoreData.gameId}`);
        await setDoc(scoreRef, {
          score: scoreData.score,
          bombsHit: scoreData.bombsHit,
          date: scoreData.date
        });
      }
    };

    const updateWeeklyLeaderboard = async (scoreData) => {
      const user = auth.currentUser;
      if (!user) return;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const username = userDoc.exists() ? userDoc.data().username : 'Anonymous';

      const now = new Date();
      const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)));
      startOfWeek.setHours(0, 0, 0, 0);
      const leaderboardId = startOfWeek.toISOString().split('T')[0];

      const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);

      try {
        await updateDoc(leaderboardRef, {
          [`scores.${user.uid}.weeklyScore`]: increment(scoreData.score),
          [`scores.${user.uid}.username`]: username,
          [`scores.${user.uid}.uid`]: user.uid,
          [`scores.${user.uid}.games`]: arrayUnion({ gameId: scoreData.gameId, score: scoreData.score })
        });
      } catch (error) {
        if (error.code === 'not-found') {
          await setDoc(leaderboardRef, {
            startDate: startOfWeek.toISOString(),
            endDate: new Date(startOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
            scores: {
              [user.uid]: {
                uid: user.uid,
                username: username,
                weeklyScore: scoreData.score,
                games: [{ gameId: scoreData.gameId, score: scoreData.score }]
              }
            }
          });
        } else {
          console.error('Error updating weekly leaderboard:', error);
        }
      }
    };

    return {
      email,
      password,
      username,
      showRegister,
      login,
      register,
      loginWithGoogle,
      forgotPassword,
      checkUserAuth,
      handleAnonymousScore,
      saveScore,
      updateWeeklyLeaderboard
    };
  },
  methods: {
    // ... existing methods ...

    async loginOrRegister() {
      // Perform login or registration
      // ...

      // If successful, check for anonymous score
      const anonymousScore = localStorage.getItem('anonymousScore');
      if (anonymousScore) {
        const parsedScore = JSON.parse(anonymousScore);
        await this.saveScore(parsedScore);
        await this.updateWeeklyLeaderboard(parsedScore.score);
        localStorage.removeItem('anonymousScore');
      }

      // Redirect back to PlayGameView
      this.$router.push('/play');
    }
  }
};
</script>

<style scoped>
.login-register-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
  box-sizing: border-box;
}

.content-wrapper {
  width: 100%;
  max-width: 400px;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-title {
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.form-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  overflow: hidden;
  width: 100%;
}

.form-box {
  padding: 20px;
}

.input-group {
  position: relative;
  margin-bottom: 25px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  background: transparent;
  box-sizing: border-box;
  padding-left: 5px; /* Add left padding to input */
}

label {
  position: absolute;
  top: 10px;
  left: 5px; /* Move label 5px to the right */
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: 0.5s;
}

input:focus ~ label,
input:valid ~ label {
  top: -20px;
  left: 5px; /* Keep consistent with the non-focused state */
  color: #764ba2;
  font-size: 12px;
}

.btn {
  width: 100%;
  padding: 10px 20px;
  background: #764ba2;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.google-btn {
  background: #4285F4;
}

@media (max-width: 480px) {
  .content-wrapper {
    margin-top: 5vh;
  }
  
  .game-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .form-box {
    padding: 15px;
  }
}

.dropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.input-group {
  position: relative;
  margin-bottom: 25px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
