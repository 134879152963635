<template>
  <div class="user-management">
    <h2>User Management ({{ users.length }})</h2>
    <table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.uid">
          <td>{{ user.uid }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>
            <button @click="showUserGames(user)">Games</button>
            <button @click="deleteUser(user)" class="delete-btn">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- User Games Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h3>{{ selectedUser.username }}'s Games</h3>
        <table>
          <thead>
            <tr>
              <th>Game Date</th>
              <th>Question</th>
              <th>Score</th>
              <th>Bombs Hit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="game in userGames" :key="game.gameId">
              <td>{{ formatDate(game.gameDate) }}</td>
              <td>{{ game.questionText }}</td>
              <td>{{ game.score }}</td>
              <td>{{ game.bombsHit }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { format } from 'date-fns';

export default {
  name: 'UserManagement',
  setup() {
    const users = ref([]);
    const showModal = ref(false);
    const selectedUser = ref(null);
    const userGames = ref([]);

    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      users.value = usersSnapshot.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      }));
    };

    const showUserGames = async (user) => {
      selectedUser.value = user;
      const userScoresCollection = collection(db, `users/${user.uid}/scores`);
      const scoresSnapshot = await getDocs(userScoresCollection);
      
      userGames.value = await Promise.all(scoresSnapshot.docs.map(async (scoreDoc) => {
        const gameData = scoreDoc.data();
        const gameDocRef = doc(db, 'games', scoreDoc.id);
        const gameDoc = await getDoc(gameDocRef);
        const gameDetails = gameDoc.data();
        return {
          gameId: scoreDoc.id,
          gameDate: gameDetails.liveDate,
          questionText: gameDetails.questionText,
          score: gameData.score,
          bombsHit: gameData.bombsHit || 0
        };
      }));

      // Sort games by most recent first
      userGames.value.sort((a, b) => {
        const dateA = a.gameDate instanceof Date ? a.gameDate : new Date(a.gameDate.seconds * 1000);
        const dateB = b.gameDate instanceof Date ? b.gameDate : new Date(b.gameDate.seconds * 1000);
        return dateB - dateA;
      });

      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      selectedUser.value = null;
      userGames.value = [];
    };

    const formatDate = (date) => {
      if (date instanceof Date) {
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      } else if (date && date.seconds) {
        return format(new Date(date.seconds * 1000), 'yyyy-MM-dd HH:mm:ss');
      }
      return 'Invalid Date';
    };

    const deleteUser = async (user) => {
      if (confirm(`Are you sure you want to delete ${user.username}?`)) {
        try {
          await deleteDoc(doc(db, 'users', user.uid));
          const userScoresCollection = collection(db, `users/${user.uid}/scores`);
          const scoresSnapshot = await getDocs(userScoresCollection);
          await Promise.all(scoresSnapshot.docs.map(doc => deleteDoc(doc.ref)));
          users.value = users.value.filter(u => u.uid !== user.uid);
          alert(`User ${user.username} has been deleted.`);
        } catch (error) {
          console.error("Error deleting user:", error);
          alert("An error occurred while deleting the user. Please try again.");
        }
      }
    };

    onMounted(fetchUsers);

    return {
      users,
      showModal,
      selectedUser,
      userGames,
      showUserGames,
      closeModal,
      formatDate,
      deleteUser
    };
  }
};
</script>

<style scoped>
.user-management {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.delete-btn {
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #d0342b;
}
</style>
