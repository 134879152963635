import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { app as appCheck } from './firebase'

import '@fortawesome/fontawesome-free/css/all.min.css';

// Function to initialize the app
function initializeApp() {
  const app = createApp(App)
  app.use(router)
  app.mount('#app')
}

// Check if appCheck is defined
if (appCheck) {
  console.log('AppCheck is initialized');
} else {
  console.warn('AppCheck not properly initialized. Continuing without it.');
}

// Initialize the app regardless of AppCheck status
initializeApp();
