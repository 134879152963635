<template>
    <div class="games-list">
      <table>
        <thead>
          <tr>
            <th>Game ID</th>
            <th>Question</th>
            <th>Live Date</th>
            <th>Times Played</th>
            <th>Times Shared</th>
            <th>Shared Percentage</th>
            <th>Correct Percentage</th>
            <th>Banked Percentage</th>
            <th>Bomb Percentage</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="game in visibleGames" :key="game.id">
            <td>{{ game.id }}</td>
            <td>{{ game.questionText }}</td>
            <td>{{ formatDate(game.liveDate) }}</td>
            <td>{{ game.timesPlayed }}</td>
            <td>{{ game.timesShared }}</td>
            <td>{{ game.sharedPercentage }}%</td>
            <td>{{ game.correctPercentage }}%</td>
            <td>{{ game.bankedPercentage }}%</td>
            <td>{{ game.bombPercentage }}%</td>
            <td>
              <button @click="editGame(game)" class="btn btn-primary">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
      <button v-if="visibleGames.length < sortedGames.length" @click="loadMore" class="btn btn-secondary">Load More</button>
    </div>
</template>

<script>
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';

export default {
  data() {
    return {
      games: [],
      gamesToShow: 10 // Number of games to show initially
    };
  },
  computed: {
    sortedGames() {
      return [...this.games].sort((a, b) => {
        const dateA = a.liveDate instanceof Date ? a.liveDate : new Date(a.liveDate.seconds * 1000);
        const dateB = b.liveDate instanceof Date ? b.liveDate : new Date(b.liveDate.seconds * 1000);
        return dateB - dateA;
      });
    },
    visibleGames() {
      return this.sortedGames.slice(0, this.gamesToShow);
    }
  },
  mounted() {
    this.fetchGames();
  },
  methods: {
    async fetchGames() {
      const gamesCollection = collection(db, 'games');
      const q = query(gamesCollection, orderBy('liveDate', 'desc'));
      const gamesSnapshot = await getDocs(q);
      this.games = await Promise.all(gamesSnapshot.docs.map(async gameDoc => {
        const gameData = gameDoc.data();
        const analyticsDoc = await getDoc(doc(db, 'analytics', gameDoc.id));
        const analyticsData = analyticsDoc.exists() ? analyticsDoc.data() : {};

        return {
          id: gameDoc.id,
          ...gameData,
          timesPlayed: analyticsData.count || 0,
          timesShared: analyticsData.timesShared || 0,
          sharedPercentage: this.calculatePercentage(analyticsData.timesShared, analyticsData.count),
          correctPercentage: this.calculatePercentage(analyticsData.allCorrect, analyticsData.count),
          bankedPercentage: this.calculatePercentage(analyticsData.banked, analyticsData.count),
          bombPercentage: this.calculatePercentage(analyticsData.bombs, analyticsData.count)
        };
      }));
    },
    calculatePercentage(part, total) {
      return total ? ((part / total) * 100).toFixed(2) : 0;
    },
    editGame(game) {
      this.$emit('edit-game', game);
    },
    formatDate(date) {
      if (date instanceof Date) {
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      } else if (date && date.seconds) {
        return format(new Date(date.seconds * 1000), 'yyyy-MM-dd HH:mm:ss');
      }
      return 'Invalid Date';
    },
    loadMore() {
      this.gamesToShow += 10; // Increase the number of games to show by 10
    }
  }
};
</script>

<style scoped>
.games-list {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}


</style>
