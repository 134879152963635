<template>
  <div class="profile-container">
    <!-- Add Home Button -->
    <router-link to="/play" class="btn rules-btn">Home</router-link>
    <div v-if="user" class="profile-content">
      <div class="username-section">
        <h2>Username: {{ username }}</h2>
        <button @click="showChangeUsernameModal = true" class="btn rules-btn">
          Change Username
        </button>
      </div>
      <div v-if="hasTrophies" class="trophy-case">
        <h4>Trophy Case</h4>
        <div class="medals">
          <span v-for="n in trophies.firstPlaceFinishes" :key="'gold'+n" class="medal gold">
            <i class="fas fa-medal"></i>
          </span>
          <span v-for="n in trophies.secondPlaceFinishes" :key="'silver'+n" class="medal silver">
            <i class="fas fa-medal"></i>
          </span>
          <span v-for="n in trophies.thirdPlaceFinishes" :key="'bronze'+n" class="medal bronze">
            <i class="fas fa-medal"></i>
          </span>
        </div>
      </div>
      <h2>Recent Games</h2>
      <div v-if="recentGames.length > 0" class="recent-games">
        <div v-for="game in recentGames" :key="game.id" class="game-card">
          <h3>{{ game.questionText }}</h3>
          <p>Date: {{ formatDate(game.date) }}</p>
          <p>Score: {{ game.score }}</p>
          <p>Bombs Hit: {{ game.bombsHit }}</p>
        </div>
        <button v-if="hasMoreGames" @click="loadMoreGames" class="btn btn-secondary">Load More</button>
      </div>
      <p v-else>No recent games found.</p>
      
    </div>
    <div v-else>
      <p>Please log in to view your profile.</p>
    </div>

    <!-- Change Username Modal -->
    <div v-if="showChangeUsernameModal" class="modal-overlay">
      <div class="modal-content">
        <h2>Change Username</h2>
        <input v-model="newUsername" placeholder="Enter new username" />
        <div class="modal-buttons">
          <button @click="changeUsername" class="btn btn-primary">Save</button>
          <button @click="showChangeUsernameModal = false" class="btn btn-secondary">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { format, differenceInDays } from 'date-fns';

export default {
  setup() {
    const user = ref(null);
    const username = ref('');
    const lastUsernameChange = ref(null);
    const showChangeUsernameModal = ref(false);
    const newUsername = ref('');
    const recentGames = ref([]);
    const lastVisibleGame = ref(null);
    const hasMoreGames = ref(true);
    const trophies = ref({
      firstPlaceFinishes: 0,
      secondPlaceFinishes: 0,
      thirdPlaceFinishes: 0,
    });

    const canChangeUsername = computed(() => {
      if (!lastUsernameChange.value) return true;
      const lastChangeDate = lastUsernameChange.value instanceof Date ? lastUsernameChange.value : new Date(lastUsernameChange.value);
      const daysSinceLastChange = differenceInDays(new Date(), lastChangeDate);
      return daysSinceLastChange >= 30;
    });

    const daysUntilNextChange = computed(() => {
      if (!lastUsernameChange.value) return 0;
      const lastChangeDate = lastUsernameChange.value instanceof Date ? lastUsernameChange.value : new Date(lastUsernameChange.value);
      const daysSinceLastChange = differenceInDays(new Date(), lastChangeDate);
      return Math.max(0, 30 - daysSinceLastChange);
    });

    const hasTrophies = computed(() => {
      return trophies.value.firstPlaceFinishes > 0 ||
             trophies.value.secondPlaceFinishes > 0 ||
             trophies.value.thirdPlaceFinishes > 0;
    });

    onMounted(async () => {
      auth.onAuthStateChanged(async (authUser) => {
        if (authUser) {
          user.value = authUser;
          await fetchUserData();
          await fetchRecentGames();
          await fetchUserTrophies();
        } else {
          user.value = null;
        }
      });
    });

    

    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.value.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        username.value = userData.username;
        lastUsernameChange.value = userData.lastUsernameChange;
      }
    };

    const fetchRecentGames = async () => {
      const gamesRef = collection(db, `users/${user.value.uid}/scores`);
      const q = query(gamesRef, orderBy('date', 'desc'), limit(10));
      const querySnapshot = await getDocs(q);

      const gamesWithQuestionText = await Promise.all(querySnapshot.docs.map(async (gameDoc) => {
        const gameData = gameDoc.data();
        if (!gameData.gameId) {
          return {
            id: gameDoc.id,
            questionText: 'Unknown Question',
            ...gameData
          };
        }
        const gameRef = doc(db, 'games', gameData.gameId);
        const gameDetails = await getDoc(gameRef);
        const questionText = gameDetails.exists() ? gameDetails.data().questionText : 'Unknown Question';
        return {
          id: gameDoc.id,
          questionText,
          ...gameData
        };
      }));

      recentGames.value = gamesWithQuestionText;
      lastVisibleGame.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      hasMoreGames.value = querySnapshot.docs.length === 10;
    };

    const loadMoreGames = async () => {
      if (!lastVisibleGame.value) return;
      const gamesRef = collection(db, `users/${user.value.uid}/scores`);
      const q = query(gamesRef, orderBy('date', 'desc'), startAfter(lastVisibleGame.value), limit(10));
      const querySnapshot = await getDocs(q);

      const newGamesWithQuestionText = await Promise.all(querySnapshot.docs.map(async (gameDoc) => {
        const gameData = gameDoc.data();
        const gameRef = doc(db, 'games', gameData.gameId); // Correct usage of doc
        const gameDetails = await getDoc(gameRef);
        const questionText = gameDetails.exists() ? gameDetails.data().questionText : 'Unknown Question';
        return {
          id: gameDoc.id,
          questionText,
          ...gameData
        };
      }));

      recentGames.value = [...recentGames.value, ...newGamesWithQuestionText];
      lastVisibleGame.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      hasMoreGames.value = querySnapshot.docs.length === 10;
    };

    const changeUsername = async () => {
      if (newUsername.value && newUsername.value !== username.value) {
        const userRef = doc(db, 'users', user.value.uid);
        await updateDoc(userRef, {
          username: newUsername.value,
          lastUsernameChange: new Date() // Store the current date
        });
        username.value = newUsername.value;
        lastUsernameChange.value = new Date();
        showChangeUsernameModal.value = false;
        newUsername.value = '';
      }
    };

    const formatDate = (date) => {
      return format(new Date(date), 'MMM dd, yyyy');
    };

    const fetchUserTrophies = async () => {
      if (!user.value) return;
      const userRef = doc(db, 'users', user.value.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        trophies.value = {
          firstPlaceFinishes: userData.firstPlaceFinishes || 0,
          secondPlaceFinishes: userData.secondPlaceFinishes || 0,
          thirdPlaceFinishes: userData.thirdPlaceFinishes || 0,
        };
      }
    };

    return {
      user,
      username,
      canChangeUsername,
      daysUntilNextChange,
      showChangeUsernameModal,
      newUsername,
      recentGames,
      hasMoreGames,
      changeUsername,
      loadMoreGames,
      formatDate,
      trophies,
      hasTrophies,
    };
  }
};
</script>

<style scoped>
.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: none; /* Remove the box-shadow to eliminate the border effect */
}

.username-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
}

.rules-btn {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  margin: 5px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
}

.rules-btn:hover {
  background: linear-gradient(135deg, #5a6fd6 0%, #6a4292 100%);
}

/* Add media query for smaller screens */
@media (max-width: 480px) {
  .rules-btn {
    font-size: 12px;
    padding: 8px 12px;
  }
}

.recent-games {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.game-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Keep the shadow for the game cards */
  transition: transform 0.3s;
}

.game-card:hover {
  transform: translateY(-5px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-sizing: border-box;
}

.trophy-case {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.medals {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.medal {
  font-size: 24px;
}

.gold {
  color: #FFD700;
}

.silver {
  color: #C0C0C0;
}

.bronze {
  color: #CD7F32;
}
</style>
