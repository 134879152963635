<template>
  <transition name="fade">
    <div v-if="show" class="bomb-animation-overlay" @animationend="handleAnimationEnd">
      <i class="fas fa-bomb" :class="{ 'explode': exploded }"></i>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BombAnimation',
  props: {
    show: Boolean
  },
  data() {
    return {
      exploded: false
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.exploded = false;
        setTimeout(() => {
          this.exploded = true;
        }, 2500); // Adjust the timing as needed
      }
    }
  },
  methods: {
    handleAnimationEnd() {
      if (this.exploded) {
        this.$emit('explosion-end');
      }
    }
  }
}
</script>

<style scoped>
.bomb-animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.bomb-animation-overlay i {
  font-size: 50vmin; /* 50% of the viewport's smaller dimension */
  color: #dc3545;
  animation: expand 1s forwards;
}

.bomb-animation-overlay i.explode {
  animation: explode 0.5s forwards;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes explode {
  0% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
