// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyDdnKEo45irEHAV6ib55sJFjkIffQHJ7-o",
    authDomain: "get7game.com",
    projectId: "get7-f3022",
    storageBucket: "get7-f3022.appspot.com",
    messagingSenderId: "326601898306",
    appId: "1:326601898306:web:7f4b98195d93302db130db",
    measurementId: "G-DZGMF46H8B"
  };

const app = initializeApp(firebaseConfig);

// Initialize AppCheck
let appCheck;
try {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider('6LdTW2EqAAAAAFuyqDD_Eo1L09FtulNhjiJqtrYa'),
    isTokenAutoRefreshEnabled: true
  });
  console.log('AppCheck initialized successfully');

  // Add visibility change listener
  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible' && appCheck) {
      try {
        // Force token refresh
        await getToken(appCheck, true);
        console.log('AppCheck token refreshed successfully');
      } catch (error) {
        console.error('Error refreshing AppCheck token:', error);
        
        // Attempt to reinitialize AppCheck if refresh fails
        try {
          appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaEnterpriseProvider('6LdTW2EqAAAAAFuyqDD_Eo1L09FtulNhjiJqtrYa'),
            isTokenAutoRefreshEnabled: true
          });
          console.log('AppCheck reinitialized successfully');
        } catch (reinitError) {
          console.error('Error reinitializing AppCheck:', reinitError);
          appCheck = null;
        }
      }
    }
  });
} catch (error) {
  console.error('Error initializing AppCheck:', error);
  appCheck = null;
}

const auth = getAuth(app);

// Set persistence to local
setPersistence(auth, browserLocalPersistence);

const db = getFirestore(app);

export { app, auth, db, appCheck };
