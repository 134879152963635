<template>
  <transition name="fade">
    <div v-if="show" class="small-bomb-animation" @animationend="handleAnimationEnd">
      <i class="fas fa-bomb explode"></i>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SmallBombAnimation',
  props: {
    show: Boolean
  },
  methods: {
    handleAnimationEnd() {
      this.$emit('animation-end');
    }
  }
}
</script>

<style scoped>
.small-bomb-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem; /* Reduced size */
  color: #d7870f;
  animation: explode 1s forwards; /* Reduced duration */
}

@keyframes explode {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
