<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="squadName">
        <h3>{{ invitedBy }} has invited you to join {{ squadName }}</h3>
        <div class="button-group">
          <button class="nav-button" @click="handleJoinClick">Join</button>
          <button class="nav-button" @click="closeModal">Cancel</button>
        </div>
      </div>
      <div v-else>
        <h3>Enter Get7 Squad Invite Code</h3>
        <input v-model="inviteCode" placeholder="Invite Code" />
        <div class="button-group">
          <button class="nav-button" @click="verifyInviteCode">Join</button>
          <button class="nav-button" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'JoinSquadView',
  data() {
    return {
      inviteCode: '',
      squadName: null,
      invitedBy: 'Someone',
    };
  },
  created() {
    this.checkInviteCodeInURL();
  },
  methods: {
    checkInviteCodeInURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const inviteCode = urlParams.get('inviteCode');
      const invitedBy = urlParams.get('invitedBy');
      if (inviteCode) {
        this.inviteCode = inviteCode;
        this.verifyInviteCode();
      }
      if (invitedBy) {
        this.invitedBy = invitedBy;
      }
    },
    closeModal() {
      this.$emit('close');
      this.inviteCode = '';
      this.squadName = null;
      if (this.$route.name === 'JoinSquad') {
        this.$router.push('/');
      }
    },
    async verifyInviteCode() {
      try {
        const squadDocRef = doc(db, 'squads', this.inviteCode);
        const squadDoc = await getDoc(squadDocRef);

        if (squadDoc.exists()) {
          const squadData = squadDoc.data();
          this.squadName = squadData.squadName;
        } else {
          alert('Invalid invite code');
        }
      } catch (error) {
        console.error('Error verifying invite code:', error);
      }
    },
    handleJoinClick() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.joinSquad(user.uid);
        } else {
          this.$router.push({
            name: 'Login',
            query: {
              redirect: this.$route.fullPath,
            },
          });
        }
      });
    },
    async joinSquad(userId) {
      try {
        // Update the user's document
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const squads = userData.squads || [];
          if (!squads.includes(this.inviteCode)) {
            squads.push(this.inviteCode);
            await setDoc(userDocRef, { squads }, { merge: true });
          }
        } else {
          await setDoc(userDocRef, { squads: [this.inviteCode] });
        }

        // Update the squad's document to include the new member
        const squadDocRef = doc(db, 'squads', this.inviteCode);
        await updateDoc(squadDocRef, {
          members: arrayUnion(userId),
        });

        alert(`Joined squad: ${this.squadName}`);
        this.closeModal();
      } catch (error) {
        console.error('Error joining squad:', error);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.nav-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 0 5px;
}

.nav-button:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
}

input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
</style>
