<template>
  <div>
    <!-- Welcome Modal -->
    <div v-if="showWelcomeModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeWelcomeModal">&times;</span>
        <h3>Welcome to Get7 Squads!</h3>
        <p>
  Squads in Get7 let you create a personalized leaderboard for you and your friends. Here's how it works:
</p>
<ul>
  <li>Join a friend's squad with their invite code.</li>
  <li>Create your own squad and invite friends.</li>
  <li>Be part of up to 5 squads at once.</li>
</ul>
<p>Have fun competing!</p>
        <button class="nav-button" @click="closeWelcomeModal">Got it!</button>
      </div>
    </div>

    <button class="nav-button" @click="showCreateSquadModal = true">Create New Squad</button>
    <button class="nav-button" @click="showJoinSquadModal = true">Join a Squad</button>
    <div v-if="hasSquads">
      <div v-if="squads && squads.length > 1" class="dropdown-container">
        <label class="dropdown-label">My Squads:</label>
        <select v-model="selectedSquadId" @change="updateSelectedSquad">
          <option v-for="squad in squads" :key="squad.id" :value="squad.id">
            {{ squad.name }}
          </option>
        </select>
        <h4>Invite Code: {{ selectedSquadId }}</h4> <!-- Use selectedSquadId as the invite code -->
        <button class="nav-button" @click="inviteFriends">Invite Friends!</button>
      </div>
      <div v-else>
        <h3>{{ squads[0].name }}</h3>
        <h4>Invite Code: {{ squads[0].id }}</h4> <!-- Use squad.id as the invite code -->
        <button class="nav-button" @click="inviteFriends">Invite Friends!</button>
      </div>
    </div>
    <div v-else>
      <p>You're not in any squads yet!</p>
    </div>
    <CreateSquadModal
      :isVisible="showCreateSquadModal"
      @close="showCreateSquadModal = false"
      @squadCreated="handleSquadCreated"
    />
    <div v-if="squadLeaderboard.length > 0" class="leaderboard-list">
      <h2>{{ selectedSquadName }} Leaderboard</h2>
      <div v-for="(player) in squadLeaderboard" :key="player.uid" class="player-row">
        <div class="rank">{{ player.rank }}</div>
        <div class="player-info">
          <span class="username" @click="showUserScores(player)">{{ player.username }}</span>
        </div>
        <div class="score">{{ player.weeklyScore }}</div>
      </div>
    </div>

    <!-- Score Details Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h3>{{ selectedUser.username }}'s Scores</h3>
        <table>
          <thead>
            <tr>
              <th>Game Date</th>
              <th>Score</th>
              <th>Bombs Hit</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="score in userScores" :key="score.gameId">
              <tr class="game-row">
                <td>{{ formatDate(score.gameDate) }}</td>
                <td>{{ score.score }}</td>
                <td>
                  <span v-for="n in score.bombsHit" :key="n" class="bomb-icon">💣
                  </span>
                </td>
              </tr>
              <tr class="question-row">
                <td colspan="3" class="question-text">{{ score.questionText }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <button class="nav-button" @click="closeModal">Close</button>
      </div>
    </div>

    <!-- Join Squad Modal -->
    <JoinSquadView
      v-if="showJoinSquadModal"
      :isVisible="showJoinSquadModal"
      @close="showJoinSquadModal = false"
      @confirm="handleJoinSquadConfirm"
    />

    <!-- Confirm Join Squad Modal -->
    <div v-if="showConfirmJoinModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeConfirmJoinModal">&times;</span>
        <h3>Join {{ confirmSquadName }}?</h3>
        <button class="nav-button" @click="joinSquad">Yes</button>
        <button class="nav-button" @click="closeConfirmJoinModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebase'; // Import auth from your firebase config
import { collection, getDocs, doc, getDoc, query, where, updateDoc, increment, setDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase'; // Import the Firestore instance
import CreateSquadModal from '../components/CreateSquadModal.vue';
import { startOfWeek, subDays, format, endOfWeek } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import JoinSquadView from './JoinSquadView.vue';

export default {
  name: 'SquadView',
  components: {
    CreateSquadModal,
    JoinSquadView, // Add the new component
  },
  data() {
    return {
      squads: null, // Initialize squads as null
      userId: null, // Initialize userId
      showCreateSquadModal: false,
      selectedSquadId: null, // Track the selected squad ID
      selectedSquadInviteCode: null, // Track the invite code of the selected squad
      squadLeaderboard: [], // Store squad leaderboard data
      showModal: false,
      selectedUser: null,
      userScores: [],
      currentWeekStart: null,
      showJoinSquadModal: false,
      showConfirmJoinModal: false,
      inviteCode: '',
      confirmSquadName: '',
      confirmSquadId: '',
      showWelcomeModal: false, // Add this to control the welcome modal
    };
  },
  created() {
    this.fetchUserId();
    this.fetchSquads();
    this.checkInviteCodeInURL();
  },
  watch: {
    squads(newSquads) {
      if (!newSquads || newSquads.length === 0) {
        this.showWelcomeModal = true; // Show the welcome modal if no squads
      }
    },
    selectedSquadId(newId) {
      if (newId) {
        this.fetchSquadLeaderboard();
      }
    }
  },
  methods: {
    fetchUserId() {
      const user = auth.currentUser;
      if (user) {
        this.userId = user.uid;
      } else {
        console.error('No user is currently logged in');
        this.userId = null;
      }
    },
    async fetchSquads() {
      try {
        const userDocRef = doc(db, 'users', this.userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userSquads = userData.squads || [];

          if (userSquads.length === 0) {
            this.squads = [];
          } else {
            const squadsRef = collection(db, 'squads');
            const snapshot = await getDocs(squadsRef);
            this.squads = snapshot.docs
              .filter(doc => userSquads.includes(doc.id))
              .map(doc => {
                const data = doc.data();
                return {
                  id: doc.id,
                  name: data.squadName || 'Unnamed Squad',
                  ...data
                };
              });

            // Automatically select the first squad
            if (this.squads.length > 0) {
              this.selectedSquadId = this.squads[0].id;
              this.selectedSquadName = this.squads[0].name;
              this.fetchSquadLeaderboard();
            }
          }
        } else {
          console.error('User document does not exist');
          this.squads = [];
        }
      } catch (error) {
        console.error('Error fetching squads:', error);
        this.squads = [];
      }
    },
    closeWelcomeModal() {
      this.showWelcomeModal = false;
    },
    updateSelectedSquad() {
      const selectedSquad = this.squads.find(squad => squad.id === this.selectedSquadId);
      if (selectedSquad) {
        this.selectedSquadInviteCode = selectedSquad.inviteCode;
        this.selectedSquadName = selectedSquad.name; // Store the selected squad name
        this.fetchSquadLeaderboard(); // Fetch the leaderboard for the selected squad
      }
    },
    async fetchSquadLeaderboard() {
      
      if (!this.selectedSquadId) return;

      try {
        const timeZone = 'America/New_York';
        const now = new Date();
        const nowNY = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");
        const nowNYDate = new Date(nowNY);

        let startOfWeekNY = startOfWeek(nowNYDate, { weekStartsOn: 1 });
        startOfWeekNY.setHours(0, 0, 0, 0);

        if (nowNYDate < startOfWeekNY) {
          startOfWeekNY = subDays(startOfWeekNY, 7);
        }

        this.currentWeekStart = startOfWeekNY;

        const leaderboardId = formatInTimeZone(startOfWeekNY, timeZone, 'yyyy-MM-dd');
        const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);
        const leaderboardDoc = await getDoc(leaderboardRef);
       

        if (leaderboardDoc.exists()) {
          const leaderboardData = leaderboardDoc.data();
          const memberIds = this.squads.find(squad => squad.id === this.selectedSquadId).members || [];
        
          const scoresArray = Array.isArray(leaderboardData.scores)
            ? leaderboardData.scores
            : Object.entries(leaderboardData.scores).map(([uid, data]) => ({
                uid,
                ...data
              }));

          const squadScores = scoresArray.filter(score => memberIds.includes(score.uid));

          const usernames = await this.fetchUsernames(squadScores.map(score => score.uid));

          this.squadLeaderboard = squadScores
            .map(player => ({
              ...player,
              username: usernames[player.uid] || player.username || 'Anonymous'
            }))
            .sort((a, b) => b.weeklyScore - a.weeklyScore)
            .map((player, index) => ({
              ...player,
              rank: index + 1
            }));
        } else {
          console.error('Weekly leaderboard document does not exist');
          this.squadLeaderboard = [];
        }
      } catch (error) {
        console.error('Error fetching squad leaderboard:', error);
        this.squadLeaderboard = [];
      }
    },
    async fetchUsernames(uids) {
      const usernamesMap = {};
      const usersRef = collection(db, 'users');
      const userDocs = await getDocs(usersRef);

      userDocs.forEach(doc => {
        if (uids.includes(doc.id)) {
          usernamesMap[doc.id] = doc.data().username || 'Anonymous';
        }
      });

      return usernamesMap;
    },
    async fetchGameIdsForCurrentWeek() {
      const startOfWeekNY = this.currentWeekStart;
      const endOfWeekNY = endOfWeek(startOfWeekNY, { weekStartsOn: 1 });
      endOfWeekNY.setHours(23, 59, 59, 999);

      const gamesRef = collection(db, 'games');
      const gamesQuery = query(
        gamesRef,
        where('liveDate', '>=', startOfWeekNY),
        where('liveDate', '<=', endOfWeekNY)
      );
      const gamesSnapshot = await getDocs(gamesQuery);

      const gameInfo = {};
      gamesSnapshot.forEach(doc => {
        const data = doc.data();
        gameInfo[doc.id] = {
          gameDate: data.liveDate,
          questionText: data.questionText,
        };
      });

      this.gameInfo = gameInfo;
      return Object.keys(gameInfo);
    },
    async showUserScores(user) {
      this.selectedUser = user;
      this.userScores = await this.fetchUserScores(user);
      this.showModal = true;
    },
    async fetchUserScores(user) {
      // Implement logic to fetch user scores
      // This should be similar to the logic in WeeklyLeaderboardView
      const gameIds = await this.fetchGameIdsForCurrentWeek();
      const scores = [];
      for (const gameId of gameIds) {
        const scoreRef = doc(db, `users/${user.uid}/scores/${gameId}`);
        const scoreDoc = await getDoc(scoreRef);
        if (scoreDoc.exists()) {
          const scoreData = scoreDoc.data();
          const gameData = this.gameInfo[gameId];
          scores.push({
            gameId,
            gameDate: gameData.gameDate,
            questionText: gameData.questionText,
            score: scoreData.score,
            bombsHit: scoreData.bombsHit || 0,
          });
        }
      }
      return scores;
    },
    closeModal() {
      this.showModal = false;
      this.selectedUser = null;
      this.userScores = [];
    },
    formatDate(date) {
      if (date instanceof Object && date.seconds) {
        date = new Date(date.seconds * 1000);
      }
      if (!(date instanceof Date) || isNaN(date)) {
        return 'Invalid Date';
      }
      return format(date, 'MMMM d');
    },
    closeJoinSquadModal() {
      this.showJoinSquadModal = false;
      this.inviteCode = '';
    },
    closeConfirmJoinModal() {
      this.showConfirmJoinModal = false;
      this.confirmSquadName = '';
      this.confirmSquadId = '';
    },
    handleJoinSquadConfirm({ squadName, squadId }) {
      this.confirmSquadName = squadName;
      this.confirmSquadId = squadId;
      this.showConfirmJoinModal = true;
    },
    async joinSquad() {
      try {
        const userDocRef = doc(db, 'users', this.userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const userSquads = userData.squads || [];
          if (!userSquads.includes(this.confirmSquadId)) {
            userSquads.push(this.confirmSquadId);
            await updateDoc(userDocRef, { squads: userSquads });
            this.fetchSquads(); // Refresh squads
          }
          this.closeConfirmJoinModal();
        } else {
          console.error('User document does not exist');
        }
      } catch (error) {
        console.error('Error joining squad:', error);
      }
    },
    async inviteFriends() {
      try {
        // Fetch the current user's username
        const userDocRef = doc(db, 'users', this.userId);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          console.error('User document does not exist');
          return;
        }

        const userData = userDoc.data();
        const username = userData.username || 'Anonymous';

        // Create the invite link with the username
        const inviteLink = `${window.location.origin}/join-squad?inviteCode=${this.selectedSquadId}&invitedBy=${encodeURIComponent(username)}`;

        // Update the analytics
    

        const analyticsDocRef = doc(db, 'analytics/squadInvites');

        await setDoc(analyticsDocRef, { invites: increment(1) }, { merge: true });

        // Share the invite link
        if (navigator.share) {
          navigator.share({
            title: 'Join my Get7 Squad!',
            text: 'Join my Get7 Squad!',
            url: inviteLink,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.error('Error sharing', error));
        } else {
          alert(`Copy this link to invite friends: ${inviteLink}`);
        }
      } catch (error) {
        console.error('Error inviting friends:', error);
      }
    },
    checkInviteCodeInURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const inviteCode = urlParams.get('inviteCode');
      if (inviteCode) {
        this.inviteCode = inviteCode;
        this.showJoinSquadModal = true;
      }
    },
    handleSquadCreated() {
      this.fetchSquads();
    },
  },
  computed: {
    hasSquads() {
      return this.squads && this.squads.length > 0;
    }
  },
};
</script>

<style scoped>
.leaderboard-list {
  max-height: 400px;
  overflow-y: auto;
}

.player-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.rank {
  font-weight: bold;
  width: 30px;
}

.player-info {
  flex-grow: 1;
  margin-left: 10px;
}

.username {
  font-weight: bold;
  cursor: pointer;
  color: #007BFF;
  text-decoration: underline;
}

.username:hover {
  color: #0056b3;
  text-decoration: none;
}

.score {
  font-weight: bold;
  color: #4CAF50;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center; /* Center align text */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: none;
}

th {
  background-color: #f4f4f4;
}

td {
  background-color: #fff;
}

.game-row td {
  border-bottom: none;
}

.question-row td {
  border-bottom: 1px solid #ddd;
}

.bomb-icon {
  font-size: 1.2em;
  margin-right: 2px;
}

.question-text {
  font-style: italic;
  color: #666;
  padding-top: 5px;
  padding-bottom: 10px;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #45a049;
}

.nav-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 10px; /* Add margin for spacing */
}

.nav-button:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
}

input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

select {
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

select:focus {
  border-color: #764ba2;
  box-shadow: 0 0 5px rgba(118, 75, 162, 0.5);
  outline: none;
}

option {
  padding: 10px;
  font-size: 16px;
}

/* Additional styles for the container to ensure proper layout */
.dropdown-container {
  max-width: 400px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the dropdown */
}

.bomb-icon {
  font-size: 1.2em;
  margin-right: 2px;
}
</style>







