<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content">
      <h2>Create New Squad</h2>
      <form @submit.prevent="createSquad">
        <div class="form-group">
          <label for="squadName">Squad Name</label>
          <input type="text" v-model="squadName" id="squadName" required />
        </div>
        <div class="form-actions">
          <button type="submit" class="nav-button">Create</button>
          <button type="button" class="nav-button" @click="closeModal">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db } from '../firebase'; // Import your Firestore instance
import { auth } from '../firebase'; // Import the Firebase Auth instance
import { doc, getDoc, setDoc } from 'firebase/firestore';

export default {
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      squadName: '',
      onlyOwnerCanInvite: true,
    };
  },
  methods: {
    async createSquad() {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User is not logged in');
        }
        const ownerUID = user.uid;
        const createdAt = new Date();
        let squadCode;
        let squadDoc;

        // Generate a unique 5-digit alphanumeric code
        do {
          squadCode = Math.random().toString(36).substring(2, 7).toUpperCase();
          squadDoc = await getDoc(doc(db, 'squads', squadCode));
        } while (squadDoc.exists());

        // Create the squad document
        await setDoc(doc(db, 'squads', squadCode), {
          ownerUID,
          createdAt,
          squadName: this.squadName,
          onlyOwnerCanInvite: this.onlyOwnerCanInvite,
          members: [ownerUID],
        });

         // Update the user's squads array
      const userDocRef = doc(db, 'users', ownerUID);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const squads = userData.squads || [];
        squads.push(squadCode);
        await setDoc(userDocRef, { squads }, { merge: true });
      } else {
        await setDoc(userDocRef, { squads: [squadCode] });
      }

        // Emit an event to notify the parent component
        this.$emit('squadCreated');
        
        this.closeModal();
      } catch (error) {
        console.error('Error creating squad:', error);
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.form-group {
  margin-bottom: 15px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.nav-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 10px; /* Add margin for spacing */
}

.nav-button:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
}

input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

select {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for Safari */
  -moz-appearance: none; /* Remove default styling for Firefox */
  cursor: pointer;
}

select:focus {
  border-color: #667eea;
  outline: none;
}

option {
  padding: 10px;
  font-size: 16px;
}

/* Add a custom arrow for the dropdown */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 80%;
}

.select-wrapper::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #667eea;
}

/* Wrap the select element in a div with class 'select-wrapper' */
</style>
