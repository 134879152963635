<template>
  <div class="complete-profile-container">
    <div class="profile-box">
      <h2>Complete Your Profile</h2>
      <form @submit.prevent="completeProfile">
        <div class="input-group">
          <input v-model="username" type="text" required>
          <label>Username for Leaderboard</label>
        </div>
        <button type="submit" class="btn">Complete Profile</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebase'; 
import { doc, setDoc, getDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';

export default {
  setup() {
    const router = useRouter();
    const username = ref('');

    const completeProfile = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          await setDoc(doc(db, 'users', user.uid), {
            username: username.value,
            email: user.email,
          }, { merge: true });

          // Handle the anonymous score after profile completion
          const tempAnonymousScore = localStorage.getItem('tempAnonymousScore');
          if (tempAnonymousScore) {
            const parsedScore = JSON.parse(tempAnonymousScore);
            await saveScore(parsedScore);
            await updateWeeklyLeaderboard(parsedScore);
            localStorage.removeItem('tempAnonymousScore');
          }

          router.push('/play');
        }
      } catch (error) {
        console.error("Error completing profile:", error);
        alert(error.message);
      }
    };

    const saveScore = async (scoreData) => {
      const user = auth.currentUser;
      if (user && scoreData) {
        const scoreRef = doc(db, `users/${user.uid}/scores/${scoreData.gameId}`);
        await setDoc(scoreRef, {
          score: scoreData.score,
          bombsHit: scoreData.bombsHit,
          date: scoreData.date
        });
      }
    };

    const updateWeeklyLeaderboard = async (scoreData) => {
      const user = auth.currentUser;
      if (!user) return;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const username = userDoc.exists() ? userDoc.data().username : 'Anonymous';

      const now = new Date();
      const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)));
      startOfWeek.setHours(0, 0, 0, 0);
      const leaderboardId = startOfWeek.toISOString().split('T')[0];

      const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);

      try {
        await updateDoc(leaderboardRef, {
          [`scores.${user.uid}.weeklyScore`]: increment(scoreData.score),
          [`scores.${user.uid}.username`]: username,
          [`scores.${user.uid}.uid`]: user.uid,
          [`scores.${user.uid}.games`]: arrayUnion({ gameId: scoreData.gameId, score: scoreData.score })
        });
      } catch (error) {
        if (error.code === 'not-found') {
          await setDoc(leaderboardRef, {
            startDate: startOfWeek.toISOString(),
            endDate: new Date(startOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
            scores: {
              [user.uid]: {
                uid: user.uid,
                username: username,
                weeklyScore: scoreData.score,
                games: [{ gameId: scoreData.gameId, score: scoreData.score }]
              }
            }
          });
        } else {
          console.error('Error updating weekly leaderboard:', error);
        }
      }
    };

    return {
      username,
      completeProfile
    };
  }
};
</script>

<style scoped>
.complete-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
  box-sizing: border-box;
}

.profile-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.input-group {
  position: relative;
  margin-bottom: 25px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  background: transparent;
  box-sizing: border-box;
}

label {
  position: absolute;
  top: 10px;
  left: 5px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: 0.5s;
}

input:focus ~ label,
input:valid ~ label {
  top: -20px;
  left: 5px;
  color: #764ba2;
  font-size: 12px;
}

.btn {
  width: 100%;
  padding: 10px 20px;
  background: #764ba2;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
</style>
