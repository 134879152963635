<!-- src/components/FireworksAnimation.vue -->
<template>
  <div v-if="show" class="fireworks-container"></div>
</template>

<script>
import { Fireworks } from 'fireworks-js';

export default {
  props: {
    show: Boolean
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.startFireworks();
      }
    }
  },
  methods: {
    startFireworks() {
  this.$nextTick(() => {
    const container = this.$el;
    const fireworks = new Fireworks(container, {
      speed: 3,
      acceleration: 1.05,
      friction: 0.97,
      gravity: 1.5,
      particles: 50,
      trace: 3,
      explosion: 5,
      boundaries: {
        top: 50,
        bottom: container.clientHeight,
        left: 50,
        right: container.clientWidth
      }
    });

    fireworks.start();

    setTimeout(() => {
      fireworks.stop();
      this.$emit('animation-end');
    }, 3500); 
  });
}
  }
};
</script>

<style scoped>
.fireworks-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}
</style>
